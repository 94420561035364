<template>
    <div class="container-fluid">

        <div class="container">
            <h3 class="font-weight-bold mb-4 centers">Les animaux entrés en fourrière</h3>
            <div class="petiteinfo">Selon les dates de mise à jour, tous nos animaux ne sont peut être pas présents sur
                le
                site internet.<br>
                N'hésitez pas à nous contacter par mail spa.dunkerque@orange.fr ou par téléphone au 03 28 61 12 00</div>
            <hr class="all">
        </div>
        <div class="container">
        </div>
        <div class="row">
            <div class="col-lg-3" v-for="animau in  animaux " v-bind:key="animau.id">
                <a class="card" href="animaux/">
                    <img :src="'https://www.admin.spa-dunkerque.fr/images/' + animau.img"
                        :alt="'photo du chien ' + animau.name + ' de la spa de dunkerque / Nord - Haut de France'"
                        :class="'tailleimgchien'" :title="animau.name + ' ' + animau.race ?? animau.couleur" style="" />
                    <div class=" fourriere" style="margin-top:0px !important">
                        <h4 class="namedogtitleback" v-if="animau.type == 'nasc'">nacs</h4>
                        <h4 v-else-if="animau.type != 'nasc'" class="namedogtitleback">{{ animau.type }}</h4>
                        <h6 class=""><strong>Race :</strong> {{ animau.race ?? "Non renseigné" }}</h6>
                        <h6 class=""><strong>Sexe :</strong> {{ animau.sexe ?? "Non renseigné" }}</h6>
                        <h6 class=""><strong>Identifié :</strong> {{ animau.ident ?? "Non renseigné" }}</h6>
                        <h6 class=""><strong>Espece :</strong> {{ animau.espece ?? "Non renseigné" }}</h6>
                        <h6 class=""><strong>Date découverte :</strong> {{ animau.decouverte ?? "Non renseigné" }}
                        </h6>
                        <h6 class=""><strong>Ville :</strong> {{ animau.ville ?? "Non renseigné" }}</h6>
                        <h6 class=""><strong>Rue :</strong> {{ animau.rue ?? "Non renseigné" }}</h6>
                        <h6 class=""><strong>Age :</strong> {{ animau.age ?? "Non renseigné" }}</h6>
                        <h6 class=""><strong>Dossier :</strong> {{ animau.dossier ?? "Non renseigné" }}</h6>
                        <h6 class=""><strong>Vie :</strong> {{ animau.vie ?? "Non renseigné" }}</h6>
                        <br>
                        <br>
                    </div>
                </a>
            </div>
            <br>
            <br>
        </div>
    </div>
    <br>
    <br>
    <br>
    <br>
    <br>
</template>

<style src="../assets/css/animauxchat.css"></style>
<script>
import convertAge from '../components/getAge.js';
export default {
    data() {
        return {
            animaux: [],
        };
    },
    mounted() {
        fetch('https://www.admin.spa-dunkerque.fr/fourriere')
            .then(response => response.json())
            .then(data => {
                this.animaux = data.animaux; // Mettez à jour chiens avec les données de l'API
                this.animauxFiltres = this.animaux;
                console.log(data.animaux);
            })
            .catch(error => {
                console.error('Il y a eu un problème avec votre requête :', error.message);
            });
    },
    methods: {

        calculateAge(dateOfBirth) {
            const age = convertAge(dateOfBirth)
            return age
        }
    }
};

</script>
