<template>
    <div class="container">
        <div class="row">
            <div class="col-lg-1"> </div>
            <div class="col-lg-5">
                <h2 class="font-weight-bold mb-4 centers" id="top">Refuge SPA de la région des Flandres - Horaires</h2>
                <hr class="all">
                <div class="globalinfo">
                    <p>
                        <i><u><strong>Les horaires d'ouverture: </strong></u></i>
                        <br> Du lundi au vendredi de 10h à 12h et de 14 h à 17 h 30
                    </p>
                    <p>
                        Le samedi matin : de 10 h à 12 h
                    </p>
                    <p>
                        Fermée le dimanche et les jours fériés
                    </p>

                    <i><u><strong>N'OUBLIEZ PAS DE PRENDRE RENDEZ-VOUS ! </strong></u></i>

                </div>
            </div>

            <div class="col-lg-6">
                <img class="test" src="../assets/images/logorond.jpg" alt="image support horraire">
            </div>
        </div>

    </div>

    <div class="container">
        <h2 class="font-weight-bold mb-4 centers">Nos animaux à adopter</h2>
        <hr class="all">
    </div>
    <!--
    <section class="center slider autoplay">
        <div v-for="chien in  chiens " v-bind:key="chien.id">
            <img :src="'https://www.admin.spa-dunkerque.fr/images/' + chien.principalimage"
                :alt="'photo du chien ' + chien.name + ' de la spa de dunkerque / Nord - Haut de France'"
                :title="chien.name + ' ' + chien.race" />
            <br>
            <h5 class="namedog"> {{ chien.name }}</h5>
            <hr>
            <div class="souscontenu">
                <h6><strong> Age: {{ chien.naissance }}</strong> </h6>
                <strong>Race : {{ chien.race }}</strong>
            </div>
        </div>
    </section>
-->
    <homeData :chiens="chiens" />
    <div class="red_button shop_now_button buttonadaptatif bluenow "> <router-link style="color:white !important"
            class="nav-link" to="/animaux">Voir tous
            nos animaux</router-link></div>

    <div class="main_slider testheaderpicture"
        style="background-image:url('../assets/images/transibyflo.png'); height: 450px;">
        <img class="transi" src="../assets/images/border-bottom-header.png" alt="image design transition">
        <div class="container fill_height">
            <div class="row align-items-center fill_height">
                <div class="col">
                    <div class="main_slider_content">


                    </div>
                </div>
            </div>

        </div>
        <img class="transibot" src="../assets/images/bottransi.png" alt="image design transition">
    </div>


    <div class="container">
        <h2 class="font-weight-bold mb-4 centers">Actualités</h2>
        <hr class="all">
    </div>
    <homeNews :publications="publications" />



</template>

<style src="../assets/css/welcome.css"></style>
<style src="../assets/css/header.css"></style>
<script setup>
import homeData from '../components/homeData.vue'
import homeNews from '../components/homeNews.vue'


</script>
<script>
export default {
    data() {
        return {
            chiens: [],
            publications: []
            // Initialisez chiens comme un tableau vide
        };
    },
    mounted() {

        const script = document.createElement('script');
        script.src = '../asset/slick/slick.js'; // Chemin vers votre fichier slick.js
        document.head.appendChild(script);
        // Effectuez votre appel API ici et mettez à jour chiens
        fetch('https://www.admin.spa-dunkerque.fr/')
            .then(response => response.json())
            .then(data => {
                this.chiens = data.chiens; // Mettez à jour chiens avec les données de l'API
                this.publications = data.publications; // Mettez à jour chiens avec les données de l'API
            })
            .catch(error => {
                console.error('Il y a eu un problème avec votre requête :', error.message);
            });
    }
};
</script>
