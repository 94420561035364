<template>
    <div class="container">
        <br>
        <h3 class="font-weight-bold mb-4 centers">{{ news.titre }}</h3>
        <hr class="all">
        <div class="row">
            <div class="col-lg-5">
                <img :src="'https://www.admin.spa-dunkerque.fr/images/' + (news.principalimage ? news.principalimage : 'default')"
                    :alt="'photo du nacs ' + news.titre + ' de la spa de dunkerque / Nord - Haut de France'" :class="''"
                    :title="news.titre" style="" />
            </div>
            <div class="col-lg-7">
                <div v-html="news.contenu"></div>
            </div>
        </div>
        <br><br>
    </div>
</template>




<script>
export default {
    data() {
        return {
            news: {}
        };
    },
    mounted() {
        fetch('https://www.admin.spa-dunkerque.fr/publications/open/' + this.$route.params.id)
            .then(response => response.json())
            .then(data => {
                console.log(data);
                this.news = data.publication
            })

            .catch(error => {
                console.error('Il y a eu un problème avec votre requête :', error.message);
            });

    }
};

</script>
