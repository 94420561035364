<template>
    <section class="center slider autoplay">
        <swiper :slides-per-view="calculateSlidesPerView()" :spaceBetween="1" :autoplay="{
            delay: 10000,
            disableOnInteraction: false,
        }" :pagination="{
            clickable: true,
        }" :navigation="true" :modules="modules" class="mySwiper">
            <swiper-slide v-for="actu in  publications  " v-bind:key="actu.id">

                <div class="container-fluid">
                    <div class="row">
                        <div class="col-lg-12">
                            <img :src="'https://www.admin.spa-dunkerque.fr/images/' + (actu.principalimage ? actu.principalimage : 'default.jpg')"
                                :alt="'photo de l actu ' + actu.nom + ' de la spa de dunkerque / Nord - Haut de France'"
                                class="actuimg" :title="actu.nom" />
                        </div>

                        <div class=" col-lg-12">
                            <br>
                            <h3 class="namedog">{{ actu.titre }}</h3>
                            <br>
                            <div class="souscontenu" v-html="actu.resumer">

                            </div>
                            <router-link class="nav-link" :to="'/publication/' + actu.id">
                                <div class="red_button shop_now_button buttonadaptatif buttonarticle"
                                    style="color: white;padding-top: 6px;">
                                    Lire la suite
                                </div>
                            </router-link>

                        </div>
                        <div class="col-lg-2"></div>
                    </div>
                </div>
            </swiper-slide>
        </swiper>
    </section>
</template>
<style src="../assets/css/welcome.css"></style>
<style src="../assets/css/header.css"></style>

<script>
// Import Swiper Vue.js components
import { Swiper, SwiperSlide } from 'swiper/vue';

// Import Swiper styles
import 'swiper/css';

import 'swiper/css/pagination';
import 'swiper/css/navigation';


// import required modules
import { Autoplay, Pagination, Navigation } from 'swiper/modules';
export default {
    components: {
        Swiper,
        SwiperSlide,
    },
    props: {
        publications: {
            type: Array,
            required: true // Indique que la prop news est requise
        },
    },
    setup() {
        return {
            modules: [Autoplay, Pagination, Navigation],
        };
    },
    methods: {
        calculateSlidesPerView() {
            return window.innerWidth < 600 ? 1 : 2;
        },
    }

};

</script>