<template>
    <div class="container">
        <h3 class="font-weight-bold mb-4 centers">Nos Besoins</h3>
        <hr class="all">
        <br>
        <div class="row margetopfirst" id="don">
            <div class="col-lg-7"> <img class="test" src="../assets/images/chatrondbyflo.jpg"
                    alt="Devenir Bénévole, soutenir la spa de haute saone / franche comté"></div>
            <div class="col-lg-5">
                <h3 class="font-weight-bold mb-4 justus">Préambule</h3>
                <p class="justu">La S.P.A. ne peut par vivre en autarcie, car les ressources des adoptions, des abandons,
                    des ventes de calendriers et autres articles ne permettent pas d'équilibrer notre bubget.</p>
                <p class="justu">Sans l'aide apportée par vous, fervents amis des animaux, il serait impossible de gérer un
                    tel afflux d'animaux abandonnés.</p>
                <p class="justu">Dans cette optique, toute aide est la bienvenue !!! </p>

                <br>
                <br>
                <div class="red_button shop_now_button buttonadaptatif bluenow"><a
                        href="https://www.paypal.me/spadedunkerque" style="line-height: 40px;">Faire un don</a></div>
            </div>
        </div>
    </div>
    <div class="container">
        <div class="row margetop">
            <div class="col-lg-5">
                <h3 class="font-weight-bold mb-4 justus">Les dons en nature</h3>
                <p class="justu">Afin d'améliorer les conditions de vies spartiates des animaux abanonnés, vous pouvez
                    nous aider en nous amenant au refuge divers produits : </p>
                <br>
                <ul>
                    <li>Croquettes chiens et chats</li>
                    <li>Litière pour chats</li>
                    <li>Alimentation, paille, copeaux pour rongeurs</li>
                    <li>Médicaments, vermifuges, anti-parasitaires...</li>
                    <li>Couvertures, serviettes...</li>
                    <li>Colliers, laisses, gamelles, abreuvoirs...</li>
                    <li>Matériel de bureau (stylos, papiers, enveloppes...)</li>
                    <li>Produits d'entretien (Javel, désinfectants, lessive...)</li>
                    <li>Cages et tout matériel animalier</li>
                </ul>
                <br>
                <br>
            </div>
            <div class="col-lg-7"> <img class="test" src="../assets/images/rond1.jpg" alt="refuge spa dunkerque"></div>
        </div>
    </div>


    <div class="container">
        <div class="row margetop">
            <div class="col-lg-7"> <img class="test" src="../assets/images/rond2.jpg"
                    alt="Devenir Bénévole, soutenir la spa de haute saone / franche comté"></div>
            <div class="col-lg-5">
                <h3 class="font-weight-bold mb-4 justus">Les dons en numéraires</h3>
                <p class="justu">Nos charges sont élevées (frais de personnel, assurances, produits d'entretien,
                    carburant, alimentation des animaux...), les frais vétérinaires varient de 2500 à 5000 euros par
                    mois, c'est pour cela que nous avons également besoin de vos dons. Vos dons peuvent être établis de
                    différentes manières :</p>
                <ul>
                    <li>Chèques à l'ordre de la S.P.A. de Dunkerque</li>
                    <li>Espèces et cartes bancaires à l'accueil du refuge</li>
                    <li>Virements bancaires (coordonnées bancaires disponibles à votre demande)</li>
                    <li>Mandats postaux</li>
                    <li>Paypal </li>
                </ul>
                <p class="justu">N'oublions pas également que vos dons sont déductibles à hauteur de 66 % de vos impôts
                    sur le revenu. Un reçu fiscal vous sera systématiquement adressé.</p>
            </div>
        </div>
    </div>
    <br>
    <br>
    <div class="container">
        <div class="row margetop">
            <div class="col-lg-5">
                <h3 class="font-weight-bold mb-4 justus">Le parrainage</h3>
                <p class="justu"> <u>Pourquoi parrainer : </u></p>
                <ul>
                    <li>Parce que vous ne pouvez pas adopter, mais que vous souhaitez tout de même nous aider.</li>
                    <li>Afin de participer aux frais de nourriture, aux frais vétérinaires... de votre filleul</li>
                    <li>Parrainer c'est faire un geste envers un animal, pour lui dire qu'on l'aime et qu'on ne l'oublie
                        pas au fond de sa cage.</li>
                </ul>
                <p class="justu"> <u>Comment parrainer : </u></p>
                <ul>
                    <li>En remplissant un bulletin de parrainage que vous trouverez auprès du refuge..</li>
                    <li> Et en nous faisant parvenir chaque mois votre participation par chèque ou par un autre moyen
                        (espèce directement au refuge, paypal, virement) ; merci d'indiquer derrière votre chèque le nom
                        de votre filleul ainsi que son numéro de médaille.</li>
                </ul>
                <p class="justu"> <u>Quel montant :</u></p>
                <ul>
                    <li>Le montant minimum du parrainage a été fixé à dix euros par mois, mais il n' y a bien sûr aucun
                        montant maximum🙂.</li>
                    <li> Il faut savoir également que nous vous fournirons en fin d'année un reçu fiscal, qui vous
                        permettra une déduction de vos impôts sur le revenu
                    </li>
                </ul>
            </div>
            <div class="col-lg-7"> <br><br><img class="test" src="../assets/images/rond3.jpg"
                    alt="refuge animalier spa dunkerque">
            </div>
        </div>
    </div>


    <div class="container">
        <div class="row margetop">
            <div class="col-lg-7"> <img class="test" src="../assets/images/rond4.jpg"
                    alt="Devenir Bénévole, soutenir la spa de dunkerque département du nord, region de haut de France">
            </div>
            <div class="col-lg-5">
                <h3 class="font-weight-bold mb-4 justus">Les legs, assurances vies et héritages</h3>
                <p class="justu">Affiliée à la Confédération Nationale des S.P.A. de lyon, par ce titre nous pouvons
                    recevoir les legs, les héritages, et les assurances vies.:</p>
                <p class="justu">Afin que votre donation aille bien à votre refuge local, il faut absolument indiquer
                    dans votre testament, dans votre assurance vie... que le bénéficiaire est la S.P.A. de Dunkerque.
                </p>
                <p class="justu">Sans nomination du nom du refuge, l'ensemble de vos biens et de vos disponibilités sera
                    systématiquement reversés à la S.P.A. de Paris. Les animaux du refuge de Dunkerque ne bénéficierons
                    pas de votre geste.</p>

            </div>
        </div>
    </div>
    <div class="container">
        <div class="row margetop">
            <div class="col-lg-5">
                <h3 class="font-weight-bold mb-4 justus">Vides greniers</h3>
                <p class="justu"> Nos bénévoles les plus actifs s'égosillent afin d'améliorer nos ressources. Dans ce
                    cadre, lors des beaux jours, vous pourrez les apercevoir lors des vides greniers locaux.</p>
                <p class="justu">Sans vous, le refuge ne pourrait pas tourner, et beaucoup d'animaux seraient encore
                    plus malheureux qu'ils ne le sont actuellement...</p>
                <p class="justu">Merci.</p>
            </div>
            <div class="col-lg-7"> <br><br><img class="test" src="../assets/images/rond5.jpg"
                    alt="refuge spa animalier Dunkerque">
            </div>
        </div>
    </div>
</template>


<style src="../assets/css/besoin.css"></style>


