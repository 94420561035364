<template>
    <div class="container-fluid">

        <div class="container">
            <h3 class="font-weight-bold mb-4 centers" id="top">Nos partenaires</h3>
            <hr class="all">
        </div>

        <div class="container">
        </div>
        <div class="row">
            <div class="col-lg-3" v-for="partenaire in  partenaires " v-bind:key="partenaire.id">
                <div class="card">
                    <img :src="'https://www.admin.spa-dunkerque.fr/images/' + partenaire.img"
                        :alt="'photo du partenaire de la spa de dunkerque / Nord - Haut de France'"
                        :title="partenaire.name" style="width: 100%;" />
                    <div class="container titlecontainershop" style="margin-top: 20px;">
                        <h4><b>{{ partenaire.name }}</b></h4>
                        <br>
                        <h5>{{ partenaire.descri }}</h5>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <br>
    <br>
    <br>
    <br>
    <br>
</template>

<style src="../assets/css/partenaire.css"></style>
<script>
export default {
    data() {
        return {
            partenaires: [],
        };
    },
    mounted() {
        fetch('https://www.admin.spa-dunkerque.fr/partenaire')
            .then(response => response.json())
            .then(data => {
                this.partenaires = data.partenaires; // Mettez à jour chiens avec les données de l'API
            })
            .catch(error => {
                console.error('Il y a eu un problème avec votre requête :', error.message);
            });
    }
};

</script>
