import { createApp } from 'vue';
import { createRouter, createWebHistory } from 'vue-router';

import App from './App.vue';
import Home from './view/homeVue.vue';
import Besoins from './view/besoinsVue.vue';
import Animaux from './view/animauxVue.vue';
import Partenaire from './view/partenaireVue.vue';
import Fourriere from './view/fourriereVue.vue';
import Adopte from './view/adopteVue.vue';
import AnimalPage from './view/animauxDetails.vue';
import NewsPage from './view/newsPage.vue';
import LegalPage from './view/legalVue.vue';

const routes = [
    { path: '/redirect', redirect: '/' },
    { path: '/', component: Home },
    { path: '/besoins', component: Besoins },
    { path: '/animaux', component: Animaux },
    { path: '/partenaire', component: Partenaire },
    { path: '/adopte', component: Adopte },
    { path: '/fourriere', component: Fourriere },
    { path: '/animaux/:type/:id', component: AnimalPage },
    { path: '/publication/:id', component: NewsPage },
    { path: '/legal', component: LegalPage }
];

const router = createRouter({
    history: createWebHistory(),
    routes
});

router.afterEach(() => {
    window.scrollTo(0, 0);
});

createApp(App).use(router).mount('#app');