<template>
    <div style="width: 100%">
        <img class="transiabso" src="../assets/images/border-bottom-header.png" alt="image de decoration">

        <iframe scrolling="no" marginheight="0" marginwidth="0"
            src="https://maps.google.com/maps?width=100%25&amp;height=600&amp;hl=en&amp;q=58%20Rue%20des%20Scieries,%2059640%20Dunkerque+(Spa%20Dunkerque)&amp;t=&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"
            width="100%" height="600" frameborder="0"></iframe><a href="https://www.maps.ie/route-planner.htm">Driving
            Route
            Planner</a>

        <img class="transibot" src="../assets/images/bottransi.png" alt="image de decoration">
    </div>
    <br />



    <div class="container" id="contact">
        <h3 class="font-weight-bold mb-4 centers">Nous contacter</h3>
        <hr class="all">
    </div>

    <footer class="page-footer font-small unique-color-dark">

        <div class="container text-center text-md-left mt-5">
            <div class="row mt-3">
                <div class="col-md-4 col-lg-5 col-xl-4 mx-auto mb-7">
                    <h6 class="text-uppercase font-weight-bold centersss">Refuge SPA de la région des Flandres -
                        Dunkerque
                    </h6>
                    <hr class="allcontact">
                    <p><strong>Association de protection animale</strong>
                        <strong> Dunkerque - Nord</strong>
                        <br> 58, rue des Scieries
                        <br> 59640 DUNKERQUE
                        <br>
                    </p>

                </div>
                <div class="col-md-5 col-lg-5 col-xl-5 mx-auto mb-7">
                    <h6 class="text-uppercase font-weight-bold centerss">Ouverture</h6>
                    <hr class="allcontact">
                    <p>
                        <i><u><strong>Les horaires d'ouverture: </strong></u></i>
                        <br> Du lundi au vendredi de 10h à 12h et de 14 h à 17 h 30
                    </p>
                    <p>
                        Le samedi matin : de 10 h à 12 h
                    </p>
                    <p>
                        Fermée le dimanche et les jours fériés
                    </p>

                    <i><u><strong>N'OUBLIEZ PAS DE PRENDRE RENDEZ-VOUS ! </strong></u></i>

                </div>
                <div class="col-md-3 col-lg-5 col-xl-3 mx-auto mb-7">

                    <h6 class="text-uppercase font-weight-bold centerss">Contact</h6>
                    <hr class="allcontact">
                    <p>
                        Mail: spa.dunkerque@orange.fr
                    </p>
                    <p>
                        Tel: 03 28 61 12 00</p>
                    <p class="retr"> Retrouvez nous sur : </p>


                    <a target="blank" href="https://www.facebook.com/chenil-refuge-spa-de-dunkerque-163643850361593">
                        <img src="../assets/images/fb.png" width="40px"
                            alt="facebook de la spa de Dunkerque  / Nord "></a>
                </div>

            </div>

        </div>
        <br><br>

        <div class="footer-copyright text-center py-3 plusbeau">
            <router-link class="legalmention" to="/legal">Mentions légales </router-link>
            <div class="moi"><br> © 2020 Copyright - Refuge SPA de la région des Flandres - Dunkerque </div>
        </div>

    </footer>
</template>

<style src="../assets/css/footer.css"></style>
